











































































import { CotisationService, OffreServices } from '@/services';
import { Component, Vue } from 'vue-property-decorator';
import {
  StartSteps,
  Contrat,
  Collaborateurs,
  Cotisations,
  CoutSante,
  AssistanceSection,
  AssistancePanel
} from '@/components';
import { OnboardingTache, RecapCotisation, Assistance, AssistanceQuery } from '@/models';
import { ONBOARDINGSTATE } from '@/utils';
import { contextEmployeur, taches, toast } from '@/store';
import { ONBOARDINGTACHESTATE, ONBOARDINGTACHETYPE } from '@/enums';
import { ERRORTYPE, getDiffBetween, getToday, formatDate } from '@projetlucie/lc-front-sdk-old';

@Component({
  components: {
    StartSteps,
    Contrat,
    Collaborateurs,
    Cotisations,
    CoutSante,
    AssistanceSection,
    AssistancePanel
  }
})
export default class VueEnsemble extends Vue {
  private onbordingStatus: ONBOARDINGSTATE | null = null;

  private taches: OnboardingTache[] | null = null;
  private cotisations: RecapCotisation | null = null;
  private sideBarState = false;
  private assistance: Assistance | null = null;

  async mounted() {
    await contextEmployeur.initContractInfo();
    this.setOnboardingState();
    this.setCotisations();
    const contrat = this.getContext?.contrat;
    if (!contrat || !contrat.numeroContrat || contrat.versionContrat === null) return;
    const query: AssistanceQuery = {
      numContrat: contrat.numeroContrat,
      version: contrat.versionContrat
    };
    this.assistance = await OffreServices.getAssistance(query);
  }

  get isSouscriptionEnValidation() {
    return this.onbordingStatus === ONBOARDINGSTATE.INPROGRESS && !this.isSouscriptionValide;
  }

  get isSouscriptionValide() {
    if (!taches.onboardingTaches || !taches.onboardingTaches.length) return false;
    return (
      taches.onboardingTaches.filter(t => t.type === ONBOARDINGTACHETYPE.INSCRIRECOLLABORATEUR)[0].statut !==
      ONBOARDINGTACHESTATE.SOON
    );
  }

  get ONBOARDINGSTATE() {
    return ONBOARDINGSTATE;
  }

  get checkCotisations() {
    return this.cotisations && (this.cotisations.precedente || this.cotisations.actuelle);
  }

  get getContext() {
    return contextEmployeur.getEmployeur;
  }

  get titleIsReady() {
    return this.getContext && this.getContext.prenom && this.getContext.raisonSocialEntreprise;
  }

  private handleSideBarState(val: boolean) {
    this.sideBarState = val;
  }

  private async setCotisations() {
    const checkDateContrat = getDiffBetween(getToday(), formatDate(this.getContext?.dateEffetContrat));
    if (
      !this.getContext?.contrat?.numeroContrat ||
      this.getContext.contrat.versionContrat === null ||
      checkDateContrat < 0
    )
      return;
    this.cotisations = await CotisationService.getRecapCotisations(
      this.getContext.contrat.numeroContrat,
      this.getContext.contrat.versionContrat
    );
  }
  private async setOnboardingState() {
    // eslint-disable-next-line no-constant-condition
    if (this.getContext?.hasAffiliationEmployeurDone) {
      this.onbordingStatus = ONBOARDINGSTATE.FINISH;
    } else {
      this.onbordingStatus = ONBOARDINGSTATE.INPROGRESS;
    }

    // TEST TACHES
    if (this.getContext && this.getContext?.contrat) {
      await taches.initOnboardingTaches({
        npp: this.getContext.numeroPersonne,
        numeroContrat: this.getContext.contrat.numeroContrat,
        versionContrat: this.getContext.contrat.versionContrat
      });
    }
    if (this.onbordingStatus == ONBOARDINGSTATE.INPROGRESS && !taches.onboardingTaches?.length) {
      toast.createAndPushToast(
        `Attention`,
        `Un problème est survenu, aucune tache n'a été trouvé pour cet utilisateur, merci de contacter le support`,
        false,
        ERRORTYPE.WARNING
      );
    }
    this.taches = taches.onboardingTaches;
  }
}
